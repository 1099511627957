import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  const linkStyle = {
    color: 'white',
    textDecoration: "underline",
    marginLeft: "5px",
    marginRight:"2px"
  };
    return (
        <>
          <section className="">
  <footer className="text-center text-white footsec">
    <div className="text-center p-3 foot-text">
      © 2020 Redmine Client by | IFISOL INFINITE SOLUTION. All Rights Reserved. 
                                  <Link to="/terms-of-service" style={linkStyle}>Terms of Service</Link> &
                                   <Link to="/privacy"  style={linkStyle}>Privacy Policy</Link>.
                                    
    </div>
  </footer>
</section>
          
        </>
    );
};
export default Footer;
