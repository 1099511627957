import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const TermsOFService = () => {
 
    return (
        <>
             <section className="section" aria-label="about">
        <div className="container">
            <h2 className="h2 section-title">Terms Of Service</h2>

          <p>Welcome to the Redmine Client App, hosted by IFI Sol Pvt Limited.</p>
          <p>Last updated: 15 August 2023.</p>
          <p>These Terms of Service ("Terms", "Terms of Service") govern your relationship with the <a href="https://www.redmineclient.com/" target="_blank" rel="noopener noreferrer">https://www.redmineclient.com/</a> website (the "Service") operated by IFI Sol Pvt Limited ("us", "we", or "our").</p>
          <p>These terms apply to the entire contents of the site, including any information you provide to us or that is downloaded via the website.</p>
          <p>Please read these Terms of Service carefully before using the website. Your access to and use of the Service is conditional on your acceptance of and compliance with these Terms.</p>
          <p>These Terms apply to all visitors, users, and others who access or use the Service. By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.</p>

          <h3 className="h3 item-title">Intellectual Property</h3>
          <p>The Service and its original content, features, and functionality are and will remain the exclusive property of Redmine Client and its licensors. The Service is protected by copyright, trademark, and other laws of foreign countries. Our trademarks and trade dress may not be used in connection with any other product or service without the prior written consent of IFI Sol Pvt Ltd.</p>

          <h3 className="h3 item-title">Links To Other Web Sites</h3>
          <p>Our Service may contain links to third-party websites or services that are not owned or controlled by Redmine Client App. Redmine Client App has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services.</p>
          <p>You further acknowledge and agree that Redmine Client shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.</p>
          <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.</p>

          <h3 className="h3 item-title">Third Party Data Sharing</h3>
          <p>We will only use your information to deliver our services to you. Redmine Client will not share your information with third parties unless we are required to do so by law. This typically includes sharing information with the Referring Agency and other third parties such as law enforcement agencies or selected third parties for the purposes of improving our services. Redmine Client will always keep your personal information secure and private at all times. You can ask for access to your personal information you have provided us and can also request your information to be corrected if you do not believe it is accurate.</p>

          <h3 className="h3 item-title">Limitation Of Liability</h3>
          <p>In no event shall Redmine Client, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages. This includes, but is not limited to, loss of profits, data, use, goodwill, or other intangible losses resulting from:</p>
          <ul>
            <li>your access to or use of or inability to access or use the Service;</li>
            <li>any content obtained from the Service; and</li>
            <li>unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed its essential purpose.</li>
          </ul>

          <h3 className="h3 item-title">Disclaimer</h3>
          <p>Your use of the Service is at your sole risk. The Service is provided on an "as is" and "as available" basis. The Service is provided without warranties of any kind, whether express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.</p>
          <p>Redmine Client and its affiliates, and licensors do not warrant that:</p>
          <ul>
            <li>the Service will function uninterrupted, secure, or available at any particular time or location;</li>
            <li>any errors or defects will be corrected;</li>
            <li>the Service is free of viruses or other harmful components; or</li>
            <li>the results of using the Service will meet your requirements.</li>
          </ul>

          <h3 className="h3 item-title">Service Availability</h3>
          <p>While Redmine Client intends that the Services should be available 24 hours a day, seven days a week, it is possible that on occasions the Service may be unavailable to permit maintenance or other development activity to take place.</p>

          <h3 className="h3 item-title">Data Transfers</h3>
          <p>We will only transfer information to persons outside of New Zealand when that information will be treated according to similar (but not lesser) standards imposed by the requirements of the Privacy Act 2020 in relation to that information.</p>

          <h3 className="h3 item-title">Governing Law</h3>
          <p>These Terms shall be governed and construed in accordance with the laws of New Zealand, without regard to its conflict of law provisions.</p>

          <h3 className="h3 item-title">Miscellaneous</h3>
          <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have between us regarding the Service.</p>

          <h3 className="h3 item-title">Changes</h3>
          <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
          <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>

          <h3 className="h3 item-title">Contact Us</h3>
          <p>If you have any questions about these Terms, please contact us at <a href="mailto:info@ifisol.com">info@ifisol.com</a></p>
        </div>
      </section>







        </>
    );
};
export default TermsOFService;
