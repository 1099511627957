import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import About from "./Components/About";
import ContactForm from "./Components/ContactForm";
import Features from "./Components/Features";
import Home from "./Components/Home";
import MyNavbar from "./Components/MyNavbar";
import Footer from "./Components/Footer";
import Privacy from "./Components/PrivacyPolicy";
import TermsOFService from "./Components/TermsofServices";

function App() {
    return (
        <div className="App">
            <MyNavbar/>
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/home" element={<Home/>} />
                 <Route path="/about" element={<About/>} /> 
                 <Route path="/features" element={<Features/>} />
                 <Route path="/contact" element={<ContactForm/>} /> 
                 <Route path="/privacy" element={<Privacy/>} /> 
                 <Route path="/terms-of-service" element={<TermsOFService/>} /> 
            </Routes>
            <Footer/>
        </div>
    );
}

export default App;