import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Privacy = () => {
  
    const linkStyle = {
        marginTop:"2rem"
      };
  return (
    <>
      <section className="section" aria-label="about" style={linkStyle}>
        <div className="container">
        <h2 className="h2 section-title">Privacy Policy</h2>
          <p>Redmine Cleint App ("us", "we", or "our") is committed to maintaining the highest standards to safeguard personal information. This Privacy Statement outlines when we collect personal information and how we handle it. Personal information is not used, shared, or transferred in connection with the website except as described in this Privacy Statement or otherwise indicated on the site.</p>

          <h3 className="h3 item-title">Collection, Storage, and Use of Personal Information:</h3>
          <p>When you use the website, you may choose to disclose personal information to us. This information may be accessed by our staff, site administrators, and occasionally by third-party contractors who are involved in administering or working on the Service, including special projects.</p>
          <p>Personal information we collect includes name, contact details, financial information (including payroll and purchasing details), employment details, health and disability information.</p>

          <h3 className="h3 item-title">Purpose of Personal Information:</h3>
          <p>The personal information you provide will be used solely for:</p>
          <ul>
            <li>Delivering our services</li>
            <li>Enhancing our services</li>
            <li>Communicating with you</li>
          </ul>
          <p>We may also use information we collect in a de-identified form to:</p>
          <ul>
            <li>Understand how we are delivering our services to you</li>
            <li>Measure the quality, impact, or effectiveness of our services</li>
            <li>Design new services, functionality, or tools</li>
          </ul>

          <h3 className="h3 item-title">Third-Party Contractors and Confidentiality:</h3>
          <p>We take data privacy seriously and ensure that any third-party contractors who access or process your personal information are bound by appropriate confidentiality agreements. These agreements, which may include Non-Disclosure Agreements (NDAs) or equivalent contractual obligations, are designed to uphold the security and confidentiality of your data. We will identify these third-party contractors where we think it’s ethical and appropriate to do so.</p>

          <h3 className="h3 item-title">Data Security Measures:</h3>
          <p>We implement rigorous security measures to safeguard your personal information, regardless of whether it is accessed by our staff, site administrators, or third-party contractors. Your data is handled in a manner consistent with our commitment to maintaining the highest standards of privacy and security.</p>

          <h3 className="h3 item-title">Data Retention:</h3>
          <p>Personal information provided is securely held by us. It may be shared with third-party contractors only as needed for the administration of the service or for collaboration on special projects. Your personal information will not be shared without your consent, except where required by law.</p>

          <h3 className="h3 item-title">Cross-Border Data Transfers:</h3>
          <p>We operate and store data on servers located outside of Australia. As a result, your personal information may be transferred to, processed in, and stored in countries other than Australia. By using our website and services, you consent to your personal information being held in data storage facilities outside of Australia. If you have any concerns about these data centres, please contact our Privacy Officer using the contact details provided in the "Contact Us" section of this privacy statement.</p>

          <h3 className="h3 item-title">Feedback:</h3>
          <p>Feedback is valuable for evaluating and enhancing the Service. Any feedback submitted through the Service is directed to relevant staff for assessment.</p>

          <h3 className="h3 item-title">Statistical Information and Cookies:</h3>
          <p>To improve the Service and configure its settings, we may collect statistical information about your visit using website 'cookies'. A cookie is a code snippet that generates a file on your computer to track the pages viewed on our Service or to support its functionality.</p>

          <h3 className="h3 item-title">Google Analytics:</h3>
          <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network. You can opt-out of having your activity on the Service made available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity. For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a></p>

          <h3 className="h3 item-title">Correcting Your Personal Information:</h3>
          <p>In accordance with the Privacy Act 2020, you have the right to access and request corrections to the personal information you have provided us. If you wish to view or amend the information that you have provided us or have privacy concerns, please contact us. We may require proof of identity before providing any personal information that you have provided us. You can reach us at:</p>
          <p>The Privacy Officer<br />
            Redmine Cleint App Limited<br />
            Email: <a href="mailto:info@ifisol.com">info@ifisol.com</a>
          </p>
        </div>
      </section>
    </>
  );
};

export default Privacy;
